.mainColor {
  background-color: #fc9db8;
  color: white;
}
.mainColor * {
  color: white !important;
}
.container {
  padding-top: 50px;
}
.text {
  white-space: pre-wrap;
  word-wrap: break-word;
}
