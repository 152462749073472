.imgContainer {
  position: relative;
}

.moreButton {
  position: absolute;
  width: 22%;
  left: 46%;
}
.circleButton {
  position: absolute;
  width: 17%;
  top: 64%;
}
